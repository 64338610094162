/* ////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// VAR /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- FAMILY --- */
$family: (Alliance, system-ui, sans-serif);

/* -------------------------------------------------------------------- BREAK --- */
$break: (
    1024px // 1
    768px  // 2
    576px  // 3
    432px  // 4
)
