/* ////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// RESET /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- RESET --- */
*:where(:not(html, canvas, iframe, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
}
*::before,
*::after {
    all: unset;
}
*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
body {
    line-height: 1;
    text-size-adjust: 100%;
}
a,
button {
    cursor: pointer;
}
ol,
ul {
    list-style: none;
}
img {
    max-width: 100%;
}
table {
    border-collapse: collapse;
}
input,
textarea {
    user-select: auto;
}
textarea {
    vertical-align: top;
    white-space: revert;
}
*:where(input, textarea)::placeholder {
    color: unset;
    opacity: 1;
}
