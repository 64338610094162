/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// TYPOGRAPHY /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- ROOT --- */
:root {
    font-size: 16px;
    @media (max-width: nth($break, 1)) {
        font-size: 14px;
    }
    @media (max-width: nth($break, 4)) {
        font-size: 12px;
    }
}

/* ----------------------------------------------------------------- DOCUMENT --- */
html,
body {
    font-family: $family;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
