/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// FOOTER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- FOOTER --- */
footer {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    color: var(--color-foreground);
    font-size: 1rem;
    text-transform: uppercase;
    @media (max-width: nth($break, 2)) {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 1fr auto;
        gap: 0.625rem 0;
    }
}
footer time {
    display: flex;
    justify-self: start;
    flex-direction: row;
    gap: 0 0.375rem;
    font-feature-settings: 'tnum';
}
footer time::before {
    content: '\a9';
}
footer code {
    font-feature-settings: 'tnum';
    white-space: nowrap;
    @media (min-width: (nth($break, 2) + 1px)) {
        justify-self: center;
    }
    @media (max-width: nth($break, 2)) {
        grid-row: 2;
    }
}
footer a {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-top: -0.0625rem;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
    justify-self: end;
    background-color: var(--color-foreground);
    @media (max-width: nth($break, 2)) {
        grid-row: 2;
    }
}
