/* ////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// HEADER /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- HEADER --- */
header {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    color: var(--color-foreground);
    font-size: 1rem;
    text-transform: uppercase;
    @media (max-width: nth($break, 2)) {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 1fr auto;
        gap: 0.625rem 0;
    }
}
header address {
    justify-self: start;
    font-feature-settings: 'tnum';
    @media (max-width: nth($break, 2)) {
        grid-row: 2;
    }
}
header h1 {
    @media (min-width: (nth($break, 2) + 1px)) {
        justify-self: center;
    }
    @media (max-width: nth($break, 2)) {
        grid-row: 1;
    }
}
header a {
    display: block;
    justify-self: end;
}
