/* ////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// FONT /// */
/* ////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- ALLIANCE --- */
@font-face {
    font-family: 'Alliance';
    font-style: normal;
    font-weight: 400;
    src: url('../font/alliance.woff2') format('woff2'), url('../font/alliance.woff') format('woff');
}
